<template>
  <div class="bank-integral-style">
    <img class="bg" src="@/assets/img/xy-card-bg.png" />
    <img
      class="couponpackage"
      src="@/assets/img/xy-coupon-package.png"
      @click="goCouponPackage"
      v-show="!isDirect"
    />

    <van-button
      class="pick"
      :loading="loading"
      loading-text="领取中..."
      @click="getIt"
    >
      立即领取
    </van-button>
    <div class="usage-details">
      <img src="@/assets/img/xy-coupon-usedetail.png" />
      <div v-if="isDirect">
        <!-- 直充兑换 -->
        <p>1、点击“立即领取”即可跳转至充值页，充值时请填写正确的账号。</p>
        <p>
          2、充值成功后无法更换和退款，因个人原因导致充值账号错误，本平台概不负责。
        </p>
        <p>3、若30分钟未到账，请重新兑换或联系客服，客服电话： 4006627299。</p>
      </div>
      <div v-else>
        <!-- 卡券兑换 -->
        <p>1、点击“立即领取”即可完成兑换操作，领取成功后，不支持退款</p>
        <p>2、兑换之后的券码可在右上角“我的券包”中查看</p>
        <p>3、券码的具体使用规则以及有效期在右上角“我的券包”中查看</p>
      </div>
    </div>
    <van-overlay :show="showMask" @click="showMask = false">
      <div class="wrapper" @click.stop>
        <div class="blocktask">
          <img src="@/assets/img/xy-coupon-right.png" />
          <h3>已领取成功</h3>
          <p>请到右上角“我的券包”中查看券码</p>
          <div class="btn">
            <a class="sure" @click="showMask = false">确定</a>
            <router-link
              :to="{ path: `/bank-coupon-package/${redeemNo}` }"
              tag="a"
              class="goCoupon"
              >到我的券包</router-link
            >
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  reqXyExchangeCoupon,
  reqXyCheckCoupon,
  checkDirectCode
} from '@/api/bank-integral-optimize'
import md5 from 'md5'
export default {
  name: 'BankIntegral',
  data() {
    return {
      orderNo: this.$route.params.orderNo,
      showMask: false,
      redeemNo: undefined,
      isDirect: false, // 是否直充兑换码，直充true
      loading: false

      // 之前6积分是用订单号与券码编号兑换的容易被发现规律，所以为了修补漏洞改成兑换码形式来兑换为了安全后端接口全部重写，前端要兼容又不能改动路由需要兼容判断(2023.3.30先不做下个版本迭代)
      // isOptimize: ![0].includes(this.$route.params?.orderNo?.indexOf('OC')) // 是否优化：有漏洞未优化false，没漏洞已优化true
    }
  },
  created() {
    this.checkType()
  },
  methods: {
    // 领取
    async getIt() {
      this.loading = true
      try {
        const { orderNo, isDirect } = this
        const seckey = orderNo + process.env.VUE_APP_BASE_KEY
        const params = {
          redeemCodeNo: orderNo,
          md5String: md5(seckey)
        }
        const { data } = await reqXyExchangeCoupon(params)
        this.loading = false
        if (isDirect) {
          window.location.href = data
          return
        }
        this.redeemNo = data
        this.showMask = true
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 跳转券包
    async goCouponPackage() {
      try {
        const { orderNo } = this
        const seckey = orderNo + process.env.VUE_APP_BASE_KEY
        const params = {
          redeemCodeNo: orderNo,
          md5String: md5(seckey)
        }
        const { data } = await reqXyCheckCoupon(params)
        this.$router.push(`/bank-coupon-package/${data}`)
      } catch (error) {
        console.log(error)
      }
    },
    // 判断类型
    async checkType() {
      const loading = this.$toast.loading({
        forbidClick: true,
        loadingType: 'spinner',
        message: '加载中...'
      })
      try {
        const { orderNo } = this
        const seckey = orderNo + process.env.VUE_APP_BASE_KEY
        const params = {
          redeemCodeNo: orderNo,
          md5String: md5(seckey)
        }
        const { data } = await checkDirectCode(params)
        loading.clear()
        this.isDirect = data
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.bank-integral-style {
  position: relative;
  .couponpackage {
    position: absolute;
    right: 0;
    top: 6.4vw;
    width: 21.6vw;
  }
  .bg {
    width: 100%;
    display: block;
  }
  .pick {
    display: block;
    width: 77.86vw;
    height: 13.34vw;
    line-height: 13.34vw;
    background: #29b2fe;
    box-shadow: 0px 14.5px 16px 3px #cbe0f9;
    border-radius: 10.4vw;
    margin: 3.2vw auto 0;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
  .usage-details {
    width: 90vw;
    border: 1px solid #0b5ebe;
    border-radius: 14px;
    box-shadow: 0px 2px 2.5px 3px #e4f1ff;
    margin: 12.93vw auto 0;
    padding: 12.1vw 7.8vw;
    box-sizing: border-box;
    font-size: 3.73vw;
    color: #0b5ebe;
    p {
      padding-bottom: 1.46vw;
    }
    & > img {
      display: block;
      width: 40.8vw;
      margin: 0 auto 4.8vw;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    .blocktask {
      width: 85.86vw;
      background-color: #fff;
      text-align: center;
      color: #171717;
      padding: 4.3vw 0 6.8vw;
      border-radius: 5.06vw;
      & > img {
        width: 9.3vw;
        display: block;
        margin: auto;
      }
      & > h3 {
        font-size: 4.26vw;
        font-weight: 500;
        padding: 3.17vw 0 3.2vw;
      }
      & > p {
        width: 53.73vw;
        margin: 0 auto 5.86vw;
        font-size: 3.73vw;
      }
      .btn {
        display: flex;
        width: 64.8vw;
        margin: auto;
        & > a {
          width: 29.3vw;
          display: block;
          line-height: 10.13vw;
          border-radius: 10.4vw;
          font-size: 3.467vw;
          letter-spacing: 1px;
        }
        .sure {
          border: 1px solid #29b2fe;
          color: #29b2fe;
          margin-right: 6.13vw;
        }
        .goCoupon {
          color: #fff;
          // box-shadow: 0px 6px 13px 0px #5da5fe;
          box-shadow: 0px 3px 14px -5px #5da5fe;
          background: linear-gradient(134deg, #29b2fe 0%, #29b2fe 100%);
        }
      }
    }
  }
}
</style>
